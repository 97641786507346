import { useContext } from 'react';
import { useRouter } from 'next/router';
import { FunnelContext } from 'contexts/FunnelContext';
import { Union } from 'utils/types';
import { ReviewSourceEnum } from 'graphql/generated/graphql';

/// //////////////////////Page Name////////////////////////////////
export const PageName = Object.freeze({
  root: 'root',
  gardasil: 'gardasil',
  eventDetails: 'eventDetails',
  eventSubmit: 'eventSubmit',
  review: 'review',
  newEvents: 'newEvents',
  search: 'search',
  medicalService: 'medicalService',
  contentFamily: 'contentFamily',
  contentWeek2306: 'contentWeek2306',
  contentOrientalMedical: 'contentOrientalMedical',
  signIn: 'signIn',
  renewalNotice: 'renewalNotice',
});

export type PageNameType = Union<typeof PageName>;

export const pagePathMap: Record<PageNameType, string> = Object.freeze({
  [PageName.root]: '/',
  [PageName.gardasil]: '/gardasil',
  [PageName.eventDetails]: '/events/[eventId]',
  [PageName.eventSubmit]: '/events/[eventId]/submit',
  [PageName.review]: '/review/[reviewId]',
  [PageName.newEvents]: '/new-events',
  [PageName.search]: '/search',
  [PageName.medicalService]: '/medical-service',
  [PageName.contentFamily]: '/content/family',
  [PageName.contentWeek2306]: '/content/week_2306',
  [PageName.contentOrientalMedical]: '/content/oriental_medical',
  [PageName.signIn]: '/sign-in',
  [PageName.renewalNotice]: '/notice/renewal',
});
/// //////////////////////////////////////////////////////////////////

/// //////////////////////DeepLinkName////////////////////////////////
export const DeepLinkName = Object.freeze({
  clinic: 'clinic',
  chatBot: 'chatBot',
  clinicEvent: 'clinicEvent',
  universalSearch: 'universalSearch',
  universalMapSearch: 'universalMapSearch',
  bookmark: 'bookmark',
  hospitalDetail: 'hospitalDetail',
  hospitalReview: 'hospitalReview',
});
export type DeepLinkNameType = Union<typeof DeepLinkName>;

export const deepLinkMap = Object.freeze({
  [DeepLinkName.clinic]: 'app/events', // Clinic Market Tab with TabBar
  [DeepLinkName.chatBot]: 'app/chatBot',
  [DeepLinkName.clinicEvent]: 'app/event/details/:eventId',
  [DeepLinkName.universalSearch]: 'app/universal/search',
  [DeepLinkName.universalMapSearch]: 'app/universal/search/map',
  [DeepLinkName.bookmark]: 'app/bookmark/1',
  [DeepLinkName.hospitalDetail]: 'app/hospital/details/:hospitalId',
  [DeepLinkName.hospitalReview]: 'app/hospital/details/:hospitalId/review',
});
/// //////////////////////////////////////////////////////////////////

/// //////////////////////DeepLinkPathName////////////////////////////
export const DeepLinkPathName = Object.freeze({
  review: 'review',
});
export type DeepLinkPathNameType = Union<typeof DeepLinkPathName>;

export const deepLinkPathMap = Object.freeze({
  [DeepLinkPathName.review]: '/review/:reviewId',
});
/// //////////////////////////////////////////////////////////////////

const useNavigation = () => {
  const router = useRouter();

  const { funnel } = useContext(FunnelContext);

  const routerReplace = (pageName: PageNameType, queryParams?: Record<string, any>, hideQueryParams?: boolean) => {
    routerMove(pageName, queryParams, true, hideQueryParams);
  };

  const routerPush = (pageName: PageNameType, queryParams?: Record<string, any>, hideQueryParams?: boolean) => {
    routerMove(pageName, queryParams, false, hideQueryParams);
  };

  const routerMove = (
    pageName: PageNameType,
    queryParams?: Record<string, any>,
    replace: boolean = false,
    hideQueryParams: boolean = false
  ) => {
    let pathname = '';
    const query = {
      funnel,
      ...queryParams,
    };

    pathname = pagePathMap[pageName];

    if (!pathname) return;
    const urlObj = {
      pathname,
      query,
    };
    const asUrlObj = {
      pathname,
      query: undefined,
    };

    if (replace) {
      router.replace(urlObj, hideQueryParams ? asUrlObj : undefined);
    } else {
      router.push(urlObj, hideQueryParams ? asUrlObj : undefined);
    }
  };

  const pushOnelink = (
    deepLinkName?: DeepLinkNameType,
    deepLinkPathName?: DeepLinkPathNameType,
    urlPathParams?: Record<string, string>
  ) => {
    let onelinkURL =
      'https://goodoc.onelink.me/7srm?pid=Eventzone&c=webtoapp&af_click_lookback=7d&af_force_deeplink=true';

    // funnel
    onelinkURL += `&funnel=${funnel}`;

    // web deep link
    onelinkURL += `&af_web_dp=${encodeURIComponent('https://www.goodoc.co.kr')}`;

    // app deep link
    if (deepLinkMap[deepLinkName]) {
      onelinkURL += `&af_dp=${encodeURIComponent(getDeeplink(deepLinkName, urlPathParams))}`;
    }

    switch (deepLinkPathName) {
      case DeepLinkPathName.review: {
        onelinkURL += '&af_adset=web_cm_detail_review_top&af_ad=01&af_sub1=210803'; // FIXME: 아직 onelink 파라미터 파악이 다 안되어 기존 코드 기준으로 나머지를 붙임 -> 파악되면 코드화 필요
        break;
      }
      default:
        // DO nothing yet
        break;
    }

    if (!onelinkURL) return;

    window.location.href = onelinkURL;
  };

  const getDeeplink = (deepLinkName?: DeepLinkNameType, urlParams?: Record<string, string>) => {
    let deeplinkUrl = '';
    switch (deepLinkName) {
      case DeepLinkName.clinicEvent: {
        const { eventId, ...rest } = urlParams;
        deeplinkUrl = `${replacePathParams(deepLinkMap[deepLinkName], { eventId })}${buildQueryString(rest)}`;
        break;
      }
      case DeepLinkName.hospitalDetail: {
        const { hospitalId, ...rest } = urlParams;
        deeplinkUrl = `${replacePathParams(deepLinkMap[deepLinkName], { hospitalId })}${buildQueryString(rest)}`;
        break;
      }
      case DeepLinkName.hospitalReview: {
        const { hospitalId, ...rest } = urlParams;
        deeplinkUrl = `${replacePathParams(deepLinkMap[deepLinkName], { hospitalId })}${buildQueryString({
          ...rest,
          source: ReviewSourceEnum.HospitalDetail,
        })}`;
        break;
      }
      default:
        deeplinkUrl = `${deepLinkMap[deepLinkName]}${buildQueryString(urlParams)}`;
    }
    return `goodoc://${deeplinkUrl}`;
  };

  const pushDeeplink = (deepLinkName?: DeepLinkNameType, urlParams?: Record<string, string>) => {
    window.location.href = getDeeplink(deepLinkName, urlParams);
  };
  return { routerReplace, routerPush, pushOnelink, pushDeeplink };
};

function replacePathParams(pathWithPlaceholder: string, urlPathParams: Record<string, string>) {
  let result = pathWithPlaceholder;
  for (const [key, value] of Object.entries(urlPathParams)) {
    result = result.replace(`:${key}`, value);
  }
  return result;
}

function buildQueryString(urlParams: Record<string, string>) {
  if (!urlParams) return '';
  const params = Object.keys(urlParams).map((key) => `${key}=${encodeURIComponent(urlParams[key])}`) || [];
  return params.length > 0 ? `?${params.join('&')}` : '';
}

export default useNavigation;
