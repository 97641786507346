import React, { useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { HeaderContext } from 'contexts/HeaderContext';
import { UserContext } from 'contexts/UserContext';
import { postCommandMessage, PostMessageCommandKey } from 'libs/bridge';

import Text from 'components/Typography/Text';

import { AnalyticsContext } from 'contexts/AnalyticsContext';

import { BookmarkButton, EventCartButton, ApplyButton, ShareButton } from 'components/Forms/IconButton';
import CloseIcon from 'assets/icons/ic_close.svg?url';
import useNavigation, { pagePathMap, DeepLinkName, PageName } from 'hooks/useNavigation';
import useUserAgent from 'hooks/useUserAgent';
import Button from 'components/Forms/Button';
import { DialogContext } from 'contexts/DialogContext';
import * as styles from './styles';

const Header = ({ handleShareClick }: any) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const router = useRouter();
  const { routerPush, routerReplace } = useNavigation();
  const { title, cartBadgeCount, onBackButtonClicked } = useContext(HeaderContext);
  const { isSignedIn, signOut } = useContext(UserContext);
  const { isMobile, isBrowser } = useUserAgent();
  const { pushDeeplink } = useNavigation();
  const { openDialog, closeDialog } = useContext(DialogContext);

  const analyticsPageName = {
    [pagePathMap.root]: '_home',
    [pagePathMap.medicalService]: '_productList',
    [pagePathMap.eventDetails]: '_productDetail',
  };

  const homePages = useMemo(() => [pagePathMap.root, pagePathMap.gardasil], []);

  const closeReferer = router.asPath.includes('?gd_referer=');
  const hasTimeQuery = router.asPath.includes('?t=');

  const showFlag = useMemo<{ [buttonType: string]: boolean }>(
    () => ({
      homeBackBtn: isMobile && homePages.includes(router.pathname),
      homeTitle: homePages.includes(router.pathname),
      backBtn: !closeReferer && !homePages.includes(router.pathname),
      closeBtn: isMobile && closeReferer,
      applyBtn: isMobile && homePages.includes(router.pathname),
      bookmarkBtn: isMobile && homePages.includes(router.pathname),
      cartBtn: false,
      shareBtn: isMobile && router.pathname === pagePathMap.eventDetails,
      signinBtn: isBrowser && [...homePages, pagePathMap.medicalService].includes(router.pathname),
    }),
    [router, isMobile]
  );

  const goBack = () => {
    if (onBackButtonClicked) {
      onBackButtonClicked();
      return;
    }

    if (window.history.state.idx > 0) {
      router.back();
    } else if (isMobile) {
      // 모바일의 경우 탭 안에 있는 ClinicMarketScreen 웹뷰의 경우만 stack이 없을 경우 home으로 replace한다.
      // 이외의 경우는 웹뷰가 Pop 될 수 있도록 처리 함.
      // 현재 ClinicMarketScreen 웹뷰를 특정할 수 있는 명시적 구분자가 없어 t 와 gd_referer 쿼리 스트링으로 판별하도록 함
      // FIXME: 모바일을 변경할 수 있으면 명시적인 구분자를 두는 것도 좋음, 명시적 구분자를 두게 되면 여기 고칠 것.
      if (hasTimeQuery && !closeReferer) {
        // Only When ClinicMarketScreen WebView (Tab Root)
        routerReplace(PageName.root);
      } else {
        postCommandMessage(PostMessageCommandKey.back);
      }
    } else {
      routerReplace(PageName.root);
    }
  };

  const goBookmark = () => {
    trackEvent(`clinic${analyticsPageName[router.pathname] || ''}_wishlistIcon_click`);
    if (isMobile) {
      if (!isSignedIn) {
        postCommandMessage(PostMessageCommandKey.auth);
      } else {
        pushDeeplink(DeepLinkName.bookmark);
      }
    }
  };

  const goApply = () => {
    router.push('/apply');
  };
  const goSearch = () => {
    pushDeeplink(DeepLinkName.universalSearch);
  };

  const handleEventCartButtonClick = () => {
    trackEvent(`clinic${analyticsPageName[router.pathname] || ''}_cart_click`);
    console.error('EventCart does not exist anymore');
  };

  const signInClicked = useCallback(() => {
    if (isSignedIn) {
      openDialog({
        title: '로그아웃 하시겠습니까?',
        primaryText: '네',
        destructiveText: '아니오',
        onDestructive: () => {
          closeDialog();
        },
        onPrimary: () => {
          signOut();
        },
      });
    } else if (isBrowser) {
      routerPush(PageName.signIn);
    } else {
      // mobile RN app
      postCommandMessage(PostMessageCommandKey.auth);
    }
  }, [isSignedIn]);

  return (
    <>
      {showFlag.homeBackBtn && (
        <div className={styles.homeBackBtn}>
          <div onClick={goBack} className={styles.backButton}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 19L5 12L12 5"
                stroke="#303642"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      <header className={styles.header}>
        <div className={styles.leftItems}>
          {showFlag.backBtn && (
            <div onClick={goBack} className={styles.backButton}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#303642"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
          {showFlag.closeBtn && (
            <img src={CloseIcon} onClick={() => postCommandMessage(PostMessageCommandKey.close)} alt="X" />
          )}
          {showFlag.homeTitle && <Text.Head1 className={styles.homeTitle}>{title || ''}</Text.Head1>}
        </div>
        <div className={styles.title}>
          {!showFlag.homeTitle && <Text.HeadNavTitle>{title || ''}</Text.HeadNavTitle>}
        </div>
        <div className={styles.rightItems}>
          {showFlag.signinBtn && (
            <Button styleType="secondaryLinkText" onClick={signInClicked} className={styles.signinButton(isSignedIn)}>
              <Text.Body1>{isSignedIn ? '로그아웃' : '로그인'}</Text.Body1>
            </Button>
          )}
          {showFlag.applyBtn && <ApplyButton onClick={goApply} />}
          {showFlag.bookmarkBtn && <BookmarkButton onClick={goBookmark} />}
          {showFlag.shareBtn && <ShareButton onClick={handleShareClick} />}
          {showFlag.cartBtn && (
            <EventCartButton
              badgeCount={cartBadgeCount}
              className={styles.cartButton}
              onClick={handleEventCartButtonClick}
            />
          )}
        </div>
      </header>
    </>
  );
};
export default Header;
