import { FC } from 'react';
import { Badge, IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core';

import CartIcon from 'assets/icons/ic_cart.svg?url';
import BookmarkPageIcon from 'assets/icons/ic_bookmarks.svg?url';
import ShareIcon from 'assets/icons/ic_share.svg?url';
import ApplyIcon from 'assets/icons/ic_apply.svg?url';

import { useEventCartContext } from 'contexts/EventCartContext';
import * as styles from './styles';

interface IconButtonProps extends MuiIconButtonProps {
  icon: any;
  alt?: string;
  badgeCount?: number;
}

const IconButton: FC<IconButtonProps> = (props) => {
  const { badgeCount = 0, icon, alt, className = '', ...restProps } = props;

  return (
    <MuiIconButton className={`${styles.iconButton} ${className}`} {...restProps}>
      <Badge badgeContent={badgeCount} color="error">
        <img src={icon} alt={alt} />
      </Badge>
    </MuiIconButton>
  );
};

type PredefinedIconButton = Omit<IconButtonProps, 'icon'>;

const generatePredefinedIconButton = (icon: any, alt?: string): FC<PredefinedIconButton> => (props) =>
  IconButton({ ...props, icon, alt });

export const BookmarkButton = generatePredefinedIconButton(BookmarkPageIcon, 'bookmark');
export const ApplyButton = generatePredefinedIconButton(ApplyIcon, 'apply');
export const ShareButton = generatePredefinedIconButton(ShareIcon, 'share');

export const EventCartButton: FC<PredefinedIconButton> = (props) => {
  const { badgeCount = 0, alt, className = '', ...restProps } = props;
  const { isEventCartAddedAnimation } = useEventCartContext();

  return (
    <IconButton
      icon={CartIcon}
      alt="event cart"
      badgeCount={badgeCount}
      className={`${isEventCartAddedAnimation ? 'tremble' : ''} ${className}`}
      {...restProps}
    />
  );
};

export default IconButton;
