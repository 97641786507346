import { css } from '@emotion/css';
import Color from 'styles/colors';

export const cartButton = css`
  margin-right: -12px !important;
`;

export const backButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  width: 56px;
  height: 56px;
  margin-left: -16px;
  cursor: pointer;
`;

export const homeBackBtn = css`
  position: sticky;
  top:0;
  z-index: 22;
  background-color: ${Color.White};
  margin-left: 12px !important;
`;

export const header = css`
  width: 100%;
  height: 3.5rem;
  z-index: 20;
  position: sticky;
  top:0;
  left: 0;
  background-color: ${Color.White};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
`;

export const leftItems = css`
  width: 4rem;
  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
`;

export const homeTitle = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  white-space: nowrap;
`;
export const title = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const rightItems = css`
  width: 4rem;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;

export const signinButton = (isSignIn: boolean) => css`
  margin-right: -${isSignIn ? '11' : '17'}px;
`;
